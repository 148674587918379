import React from 'react'

import SearchListTemplate from '../../components/templates/SearchListTemplate'

const AdsPage = () => {
  const title = 'Trouver Mon Local Pro - Nos Annonces'
  const description = 'Tous les locaux professionnels du 2 au 5 pièces à vendre et louer. Prix direct Promoteur.'

  return (
    <SearchListTemplate
      title={title}
      description={description} />
  )
}

export default AdsPage
